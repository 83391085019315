import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { StylesProvider } from '@material-ui/core/styles';

import Header from './Header';
import Footer from './Footer';
import './layout.css';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  const SmoothScroll = require('smooth-scroll');
  SmoothScroll('a[href*="#"]');
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // injectFirst makes MUI style import appear after my styles, so my styles always override them
  return (
    <StylesProvider injectFirst>
      <Header />
      <main>{children}</main>
      <Footer siteTitle={data.site.siteMetadata.title} />
    </StylesProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
