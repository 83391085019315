import PropTypes from 'prop-types';
import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import {
  footer,
  footerContent,
  icon,
  lowerFooter,
  socialLinkedin,
  socialInstagram,
  socialTwitter
} from './Footer.module.css';

const Footer = ({ siteTitle }) => (
  <footer className={footer}>
    <div className={footerContent}>
      <a href="http://ca.linkedin.com/pub/alex-rechetov/11/b97/26" target="_blank" rel="noopener noreferrer" className={socialLinkedin}>
        <LinkedInIcon className={icon} width="24" height="24" />
        <span>LinkedIn</span>
      </a>
      <a href="https://www.instagram.com/alexrec22" target="_blank" rel="noopener noreferrer" className={socialInstagram}>
        <InstagramIcon className={icon} width="24" height="24" />
        <span>Instagram</span>
      </a>
      <a href="http://www.twitter.com/rechetov" target="_blank" rel="noopener noreferrer" className={socialTwitter}>
        <TwitterIcon className={icon} width="24" height="24" />
        <span>Twitter</span>
      </a>
    </div>

    <div className={lowerFooter}>
      {`© ${new Date().getFullYear()} ${siteTitle}. All Rights Reserved.`}
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string
};

Footer.defaultProps = {
  siteTitle: ''
};

export default Footer;
