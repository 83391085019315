import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Drawer,
  IconButton,
  Toolbar,
  useScrollTrigger
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {
  logo,
  menuItem,
  dark,
  light,
  mobileOnly,
  desktopOnly,
  drawer
} from './Header.module.css';

const menuItems = [{
  to: '#home',
  label: 'Alex Rechetov',
  className: logo
}, {
  to: '#about',
  label: 'About'
}, {
  to: '#techSkills',
  label: 'Tech Skills'
}, {
  to: '#contact',
  label: 'Contact'
}];

const MenuItem = ({
  to,
  label,
  className,
  onClick
}) => (
  <a
    data-scroll
    href={to}
    className={`${menuItem} ${className}`}
    onClick={onClick}
  >
    {label}
  </a>
);

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};

MenuItem.defaultProps = {
  className: '',
  onClick: undefined
};

const ScrollHandler = (props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.window ? window() : undefined
  });

  return React.cloneElement(props.children, {
    className: trigger ? dark : light
  });
};

const Menu = ({ onClick }) => (
  menuItems.map(({ to, label, className }) => (
    <MenuItem
      key={to}
      to={to}
      label={label}
      className={className}
      onClick={onClick}
    />
  ))
);

const Header = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <ScrollHandler>
      <AppBar position="fixed">
        <Toolbar>
          <div className={mobileOnly}>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpen(!open)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={open}
              onClose={() => setOpen(false)}
              className={`${mobileOnly} ${drawer}`}
            >
              <Menu onClick={() => setOpen(false)} />
            </Drawer>
          </div>
          <div className={desktopOnly}>
            <Menu />
          </div>
        </Toolbar>
      </AppBar>
    </ScrollHandler>
  );
};

export default Header;
